import styled from 'styled-components';

export const Container = styled.div``;

export const Title = styled.div`
  position: absolute;
  top: -15px;
  left: 0;
  font-size: 20px;
  background-color: #fff;
  padding: 5px 20px 0 0;
`;

export const CurrentSubBox = styled.div`
  border-top: 1px solid #ccd2e3;
  border-bottom: 1px solid #ccd2e3;
  padding: 25px 0;
  position: relative;
`;

export const AddonBox = styled.div`
  border-top: 1px solid #ccd2e3;
  border-bottom: 1px solid #ccd2e3;
  padding: 25px 0;
  margin-top: 50px;
  position: relative;
  margin-bottom: 30px;

  @media (max-width: 425px) {
    & .price {
      flex: 100px;
    }
  }
`;

export const FlexBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: space-between;

  &.total {
    padding: 15px 0;
  }
  &.grand.total {
    font-size: 20px;
    border-top: 1px solid #ccd2e3;
  }
`;
export const ImageContainer = styled.div`
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px;
  width: 85px;
`;
export const Image = styled.img`
  width: 75px;
  height: 75px;
`;
export const ProductDetails = styled.div`
  flex: 55%;
  padding-left: 15px;
`;

export const ProductDetailsName = styled.div`
  font-size: 20px;
  @media (max-width: 600px) {
    font-size: 18px;
  }
`;
export const ProductDetailsDesc = styled.div`
  font-size: 16px;
`;

export const ProductPrice = styled.div`
  flex: 25%;
  text-align: right;
`;

export const FreeTrial = styled.div``;

export const Price = styled.div`
  color: #99a4c7;
  text-decoration: line-through;
`;
export const DiscountedPrice = styled.div`
  color: #bc2e3e;
  font-family: 'Neuzeit Grotesk', sans-serif;
`;

export const AddonPrice = styled.div`
  color: #001c72;
`;

export const FirstHalf = styled.div`
  flex: 50%;
`;

export const SecondHalf = styled.div`
  flex: 50%;
  text-align: right;
`;
