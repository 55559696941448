import React, { useContext } from 'react';
import FunnelContext from '../../context/FunnelContext';
import * as S from './ActiveSubscriptionConfirmationV1.styles';
import useLocalStorage from '../../hooks/useLocalStorage';

const ActiveSubscriptionConfirmationV1 = () => {
  const { currency, pageDesign } = useContext(FunnelContext);
  const currencySymbol = currency?.symbol || '$';
  const [crossSellResponse] = useLocalStorage('cross-sell-response-data');
  const [crossSellSettings] = useLocalStorage('cross-sell-settings');
  const currentProduct = crossSellResponse?.products.filter(
    item => item.isMainProduct === true
  );
  const addonProduct = crossSellResponse?.products.filter(
    item => item.isMainProduct === false
  );
  const checkoutData = crossSellResponse?.checkoutData;

  const hideFreeTrial = !crossSellSettings?.specialOffer || false;
  const crossSellDiscountPercentage = () => {
    switch (crossSellSettings?.discountType) {
      case 'DISCOUNT_35':
        return 35;
      case 'DISCOUNT_40':
        return 40;
      case 'DISCOUNT_50':
        return 50;
      case 'DISCOUNT_55':
        return 55;
      case 'DISCOUNT_FREE_UNIT':
        return 0;
      case 'DISCOUNT_25':
      default:
        return 25;
    }
  };

  return (
    <>
      {crossSellResponse && (
        <S.Container pageDesign={pageDesign}>
          {currentProduct && (
            <S.CurrentSubBox>
              <S.Title>Current Subscription</S.Title>
              <S.FlexBox>
                <S.ImageContainer>
                  <S.Image src={currentProduct[0]?.image} />
                </S.ImageContainer>
                <S.ProductDetails>
                  <S.ProductDetailsName>
                    {currentProduct[0]?.name}
                  </S.ProductDetailsName>
                  <S.ProductDetailsDesc>
                    {currentProduct[0]?.frequency}
                  </S.ProductDetailsDesc>
                </S.ProductDetails>
                <S.ProductPrice>
                  {currencySymbol}
                  {currentProduct[0]?.discountedPrice.toFixed(2)}
                </S.ProductPrice>
              </S.FlexBox>
            </S.CurrentSubBox>
          )}
          {addonProduct && (
            <S.AddonBox>
              <S.Title>Add-on</S.Title>
              <S.FlexBox>
                <S.ImageContainer>
                  <S.Image src={addonProduct[0]?.image} />
                </S.ImageContainer>
                <S.ProductDetails className="details">
                  <S.ProductDetailsName>
                    {addonProduct[0]?.name}
                  </S.ProductDetailsName>
                  <S.ProductDetailsDesc>
                    {addonProduct[0]?.frequency}
                  </S.ProductDetailsDesc>
                </S.ProductDetails>
                <S.ProductPrice className="price">
                  {!hideFreeTrial && (
                    <>
                      <S.FreeTrial>Free Trial</S.FreeTrial>
                      <S.Price>
                        {currencySymbol}
                        {addonProduct[0]?.rrp.toFixed(2)}
                      </S.Price>
                      <S.DiscountedPrice>
                        (then {currencySymbol}
                        {checkoutData?.discounted_price.toFixed(2)})
                      </S.DiscountedPrice>
                    </>
                  )}

                  {hideFreeTrial && (
                    <>
                      <S.AddonPrice>
                        {currencySymbol}
                        {addonProduct[0]?.discountedPrice.toFixed(2)}
                      </S.AddonPrice>
                      <S.DiscountedPrice>
                        (save {crossSellDiscountPercentage()}%)
                      </S.DiscountedPrice>
                    </>
                  )}
                </S.ProductPrice>
              </S.FlexBox>
            </S.AddonBox>
          )}

          <S.FlexBox className="total">
            <S.FirstHalf>Total RRP</S.FirstHalf>
            <S.SecondHalf>
              {currencySymbol}
              {crossSellResponse.totalRRP.toFixed(2)}
            </S.SecondHalf>
          </S.FlexBox>
          <S.FlexBox className="total">
            <S.FirstHalf>Total Savings</S.FirstHalf>
            <S.SecondHalf>
              ({currencySymbol}
              {crossSellResponse.totalSavings.toFixed(2)})
            </S.SecondHalf>
          </S.FlexBox>
          <S.FlexBox className="total">
            <S.FirstHalf>Taxes</S.FirstHalf>
            <S.SecondHalf>
              {currencySymbol}
              {crossSellResponse.tax.toFixed(2)}
            </S.SecondHalf>
          </S.FlexBox>
          <S.FlexBox className="grand total">
            <S.FirstHalf>Total</S.FirstHalf>
            <S.SecondHalf>
              {currencySymbol}
              {crossSellResponse.totalAmount.toFixed(2)}
            </S.SecondHalf>
          </S.FlexBox>
        </S.Container>
      )}
    </>
  );
};

export default ActiveSubscriptionConfirmationV1;
