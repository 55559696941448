import React from 'react';
import ActiveSubscriptionConfirmationV1 from './ActiveSubscriptionConfirmationV1';

const ActiveSubscriptionConfirmation = props => {
  const { designVersion } = props;

  switch (designVersion) {
    default:
    case 'version1':
      return <ActiveSubscriptionConfirmationV1 {...props} />;
  }
};

export default ActiveSubscriptionConfirmation;
